<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Litter Weight Record</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table table-bordered" divider="true">
              <tbody>
                <tr>
                  <td>Pop #</td>
                  <td>Sex</td>
                  <td>Colour</td>
                  <td>ID</td>
                  <td>D0</td>
                  <td>D1</td>
                  <td>D2</td>
                  <td>D3</td>
                  <td>D4</td>
                  <td>D5</td>
                  <td>D6</td>
                  <td>D7</td>
                  <td>D8</td>
                  <td>D9</td>
                  <td>D10</td>
                  <td>D11</td>
                  <td>D12</td>
                  <td>D13</td>
                  <td>Actions</td>
                </tr>

                <tr
                  v-for="(puppy, index) in litter.puppies"
                  v-bind:key="puppy.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ puppy.gender }}</td>
                  <td>{{ puppy.colour_id ? puppy.colour.name : "" }}</td>
                  <td>{{ puppy.name }}</td>
                  <td v-for="(weight, index) in puppy.test" v-bind:key="index">
                    {{ weight }}
                  </td>

                  <td>
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      color="green lighten-4 green--text"
                      :to="{
                        name: 'module-lhl-dogs-individual',
                        params: { dogId: puppy.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  components: {},

  data() {
    return {
      searchTerm: "",
    };
  },

  computed: {
    litter() {
      let litter = this.$store.getters["lhl/litters/get"];

      litter.puppies.forEach((puppy, index) => {
        let test = [];

        var from = moment(puppy.dob, "YYYY-MM-DD");
        var to = moment(puppy.dob, "YYYY-MM-DD").add(14, "days");

        for (var m = moment(from); m.isBefore(to); m.add(1, "days")) {
          test.push("N/A");
        }
        var test2 = [];
        puppy.weights.forEach((weight) => {
          var dayNumber2 = this.day(puppy.dob, weight.date);

          test2.dayNumber = dayNumber2;
          test2.value = weight.value;

          if (dayNumber2 < 14) {
            test[dayNumber2] = weight.value;
          }
        });

        litter.puppies[index]["test"] = test;
      });

      return litter;
    },
  },
  methods: {
    day(dob, weightDate) {
      var b = moment(dob, "YYYY-MM-DD");
      var a = moment(weightDate, "YYYY-MM-DD");

      var days = a.diff(b, "days");

      return days;
    },
  },
};
</script>
